import React from "react";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View,} from "@react-pdf/renderer";
import ViewPDF from "../ViewPDF";
import {Button,Typography} from "@material-ui/core";
import NOAPHeader from "../static/img/NOAPHeader.png";
import NOAPFooter from "../static/img/NOAPFooter.png";
import NOAPFooterNew from "../static/img/NOAPFooterNew.png";
import eSig from "../static/img/eSigg.png";

// Create styles
const styles = StyleSheet.create({
   page: {
      padding: 0,
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
   },
   body: {
      paddingHorizontal: 70,
   },
   footer: {},
   borderBottom: {
      borderBottomColor: "black",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
   },
});

// Create Document Component
export default function NOAP({data}) {
   return (
      <Document
         title={"Notice of Admission to the Program"}
         author={"University of Southeastern Philippines"}
      >
         <Page size="A4" style={styles.page}>
            <View>
               <Image src={NOAPHeader}/>
               <View style={styles.body}>
                  <View style={{width: 110, marginBottom: 24}}>
                     <View style={styles.borderBottom}>
                        <Text style={{textAlign: "center"}}>
                           {data.date}
                        </Text>
                     </View>
                     <Text style={{textAlign: "center"}}>(Date)</Text>
                  </View>
                  <View style={{marginBottom: 48}}>
                     <Text style={{textDecoration: 'underline', fontWeight: 'bold'}}>Mr./Ms. {data.name}</Text>
                  </View>
                  <View style={{marginBottom: 24}}>
                     <Text style={{marginBottom: 8}}>Sir/Madam:</Text>
                     <Text style={{textIndent: 40, marginBottom: 8, textAlign: 'justify'}}>
                        Please take notice that after due evaluation, you are found to qualify, and is now eligible, for
                        enrolment to the program specified hereunder:
                     </Text>
                     <View style={{paddingHorizontal: 40}}>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                           <View style={{width: '60%'}}>
                              <Text>
                                 College: <Text style={{textDecoration: 'underline'}}>{data.college}</Text>
                              </Text>
                           </View>
                           <View style={{width: '40%'}}>
                              <Text>
                                 Campus: <Text style={{textDecoration: 'underline'}}>{data.campus}</Text>
                              </Text>
                           </View>
                        </View>
                        <Text>
                           Approved Program/Course to Enroll: <Text style={{textDecoration: 'underline'}}>
                           {data.program}
                        </Text>
                        </Text>
                        <Text>
                           Year Level: <Text style={{textDecoration: 'underline'}}>
                           {data.yearLevel}
                        </Text>
                        </Text>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                           <View style={{width: '50%'}}>
                              <Text>
                                 Semester: <Text style={{textDecoration: 'underline'}}>{data.semester}</Text>
                              </Text>
                           </View>
                           <View style={{width: '50%'}}>
                              <Text>
                                 Academic Year: <Text style={{textDecoration: 'underline'}}>{data.academicYear}</Text>
                              </Text>
                           </View>
                        </View>
                     </View>
                  </View>
                  <View style={{marginBottom: 24}}>
                     <Image src={eSig} style={{width: 100, height: 60, margin: 'auto', top: 8}}/>
                     <Text style={{textAlign: 'center'}}>FEDELIS C. BONOCAN, Ph.D.</Text>
                     <Text style={{textAlign: 'center'}}>Deputy Director</Text>
                     <Text style={{textAlign: 'center'}}>Admissions Unit</Text>
                  </View>
               </View>
            </View>
            <Image src={NOAPFooterNew} style={styles.footer}/>
         </Page>
      </Document>
   );
}

export function ViewNOAP({data}) {
   return <ViewPDF documentComponent={<NOAP data={data}/>}/>;
}

export function DownloadNOAP({fileName, data}) {
   return (
      <PDFDownloadLink document={<NOAP data={data}/>} fileName={fileName || "NOAP.pdf"}>
         {({blob, url, loading, error}) => {
            console.log({blob, url, loading, error});

            // if (url) {
            //   const link = document.createElement("a");
            //   link.download = fileName;
            //   link.href = url;
            //   document.body.appendChild(link);
            //   link.click();
            //   document.body.removeChild(link);
            //   // if (isFunction(afterDownloaded)) afterDownloaded();
            // }

            return <Button style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}}  variant="outlined" color="primary">
                  <Typography style={{textDecoration:"none"}}>Download NOAP</Typography>
               </Button>;
         }}
      </PDFDownloadLink>
   );
}
