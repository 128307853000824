import React, { useEffect, useState } from 'react';
import { useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CircularProgress,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

import ApplicationTrackingDetails from "./ApplicationTrackingDetails";
import TestScheduleSelector from "./TestScheduleSelector";

// actions
import { 
    getApplicationStatus,
    getApplicantPassportPhoto
} from "../../redux/actions/applicant";

// styling
const styles = theme => ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`

    },
    confirmSubmissionMsg: {
        fontFamily: `"Quicksand", "sans-serif"`,
        fontSize: 18,
        fontWeight: 400,
        color: 'black'
    },
    txtFields: {
        margin: theme.spacing(1),
        width: `95%`
    },
});


const ApplicationTracker = (props) => {

    const { classes } = props;

    const applicant = useSelector(state => state.applicant);

    const [ appNoToTrack, setAppNoToTrack ] = useState(null);
    const [ passcodeToTrack, setPasscodeToTrack ] = useState(null);

    const [ openSubmitResponseDialog, setOpenSubmitResponseDialog ] = useState(false);
    const [ submissionResponse, setSubmissionResponseDialog ] = useState(null);


    const changeHandler = (e) => {
        if(e.target.id === "appNo"){
            setAppNoToTrack(e.target.value);
        }else if(e.target.id === "passCode"){
            setPasscodeToTrack(e.target.value);
        }
    }

    const trackApplication = () => {
        const application = {
            appNo: appNoToTrack,
            passCode: passcodeToTrack
        }
        getApplicationStatus(application)
    }

    const onSubmitTestScheduleSuccess = (response) => {
        // alert(`Submit Response: ${response.statusText}`);
        setSubmissionResponseDialog(response);
        setOpenSubmitResponseDialog(true);
        trackApplication();
    }

    const onSubmitTestScheduleFailed = (response) => {
        // alert(`Submit Response Failed: ${response.statusText}`);
        setSubmissionResponseDialog(response);
        setOpenSubmitResponseDialog(true);
        trackApplication();
    }

    return (
        <div>
            <Grid item container xs={12} sm={12}>
                <Grid item xs={12} spacing={2} sm={4} style={{margin:"25px auto"}} alignContent="center" alignItems="center" align="center">
                    <TextField 
                        id="appNo"
                        name="appNo"
                        label="Application Number"
                        onBlur={changeHandler}
                        fullWidth
                        variant="outlined"
                        required
                        className={classes.txtFields}
                    />
                    <TextField 
                        id="passCode"
                        name="passCode"
                        label="Pass Code"
                        onBlur={changeHandler}
                        fullWidth
                        variant="outlined"
                        required   
                        className={classes.txtFields}
                    />

                    <Button 
                        variant="contained" align="center" color="primary" 
                        onClick={trackApplication}
                        style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} >
                            Search
                    </Button>
                    <div> 
                        <Button 
                            style={{display: 'none', fontSize:'0.8rem',textTransform:'none',textDecoration:'underline'}} align="center" color="primary" 
                            // onClick={this.handleForgotAccountBtnClicked}
                        >
                                Forgot Account
                        </Button>
                    </div>

                    {applicant.applicationStatus ? 
                        <ApplicationTrackingDetails 
                            applicationStatus = {applicant.applicationStatus}
                        />
                    : null }

                    {/* {applicant.applicationStatus && applicant.applicationStatus.hasSelectedSchedule === 'false' 
                        && applicant.applicationStatus.validationStatus === 'Validated' ?
                        <TestScheduleSelector 
                            appNo = {applicant.applicationStatus.appNo}
                            appCampusID = {applicant.applicationStatus.Choice1_CampusID}
                            onSubmitTestScheduleSuccess = {onSubmitTestScheduleSuccess}
                            onSubmitTestScheduleFailed = {onSubmitTestScheduleFailed}
                        />
                    : null } */}
                </Grid>
            </Grid>
            
            <Dialog open={openSubmitResponseDialog}>
                <DialogTitle>Test Schedule Successfully Saved</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your have successfully selected your admission test schedule. 
                        Please download your Permit to take the Admission Test and bring a printed copy with
                        you on your scheduled test date.
                        Thank you.
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => setOpenSubmitResponseDialog(false)}>OK</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            

        </div>
    );
};

export default withStyles(styles)(ApplicationTracker);